<template>
  <a-modal :title="detail.id ? '修改目录' : '新增目录'" :visible="visible" :footer="null" @cancel="cancel">
    <a-form
      :form="form"
      @submit="handleSubmit"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
    >
      <a-form-item label="上级目录">
        <a-cascader
          :changeOnSelect="true"
          placeholder="不选就是根目录"
          v-decorator="[
                  'pid',
                  {
                    initialValue: detail.pidList,
                  }
                ]"
          :options="list"
          :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
        />
      </a-form-item>
      <a-form-item label="目录名">
        <a-input
          v-decorator="[
          'name',
          { initialValue: detail.name,
            rules: [{ required: true, message: '请输入！' }] },
        ]"
        />
      </a-form-item>
      <a-form-item label="排序">
        <a-input-number
          style="width: 100%"
          v-decorator="[
          'sort',
          {
            initialValue: detail.sort,
          }
        ]"
        />
      </a-form-item>
      <a-form-item label="状态">
        <a-radio-group
          v-decorator="[
          'status',
          {
            initialValue: detail.status,
             rules: [{ required: true, message: '请选择！' }] },
        ]"
        >
          <a-radio :value="1">正常</a-radio>
          <a-radio :value="0">禁用</a-radio>
        </a-radio-group>
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button :loading="loading" type="primary" html-type="submit">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import request from "@/api/request";
function add(data) {
  return request({
    url: "/office-service/study/course/index/add",
    method: "post",
    data
  });
}

function edit(data) {
  return request({
    url: "/office-service/study/course/index/update",
    method: "post",
    data
  });
}

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false
    };
  },
  methods: {
    cancel() {
      this.form.resetFields();
      this.$emit("cancel");
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.loading = true;
          if (this.detail.id) {
            edit({
              id: this.detail.id,
              ...values,
              pid: values.pid ? values.pid[values.pid.length - 1] : null,
              sort: typeof values.sort === "number" ? values.sort : 0
            })
              .then(() => {
                this.$emit("refresh");
                this.cancel();
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            add({
              ...values,
              pid: values.pid ? values.pid[values.pid.length - 1] : null,
              sort: typeof values.sort === "number" ? values.sort : 0
            })
              .then(() => {
                this.$emit("refresh");
                this.cancel();
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    }
  }
};
</script>
<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="right">
        <a-space>
          <a-button @click="getDirectory">刷新</a-button>
          <a-button type="primary" @click="add">新增目录</a-button>
        </a-space>
      </div>
      <Padding />

      <a-table
        bordered
        :data-source="tree"
        :loading="directoryLoading"
        :pagination="false"
        rowKey="id"
      >
        <a-table-column title="目录名称" key="name" data-index="name" />

        <a-table-column title="排序值" key="sort" width="80px" data-index="sort" align="center" />

        <a-table-column title="状态" key="status" align="center" width="120px">
          <template slot-scope="text">
            <a-badge v-if="text.status === 1" text="正常" status="success" />
            <a-badge v-else text="禁用" status="error" />
          </template>
        </a-table-column>

        <a-table-column key="control" align="center" width="170px" title="操作">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="edit(text)">修改</a>
              <a
                href="#"
                class="danger"
                @click.prevent="remove(text)"
              >{{ text.status === 0 ? '恢复' : '禁用' }}</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>

      <Editor
        :list="tree"
        :visible="visible"
        :detail="detail"
        @cancel="cancel"
        @refresh="getDirectory"
      />
    </a-card>
  </div>
</template>

<script>
import Editor from "./editor.vue";
import request from "@/api/request";
import study from "@/mixins/study";

function update(data) {
  return request({
    url: "/office-service/study/course/index/update",
    method: "post",
    data
  });
}

function del(data) {
  return request({
    url: "/office-service/study/course/index/del",
    method: "post",
    data
  });
}

export default {
  mixins: [study],

  components: {
    Editor
  },
  data() {
    return {
      visible: false,
      detail: {}
    };
  },

  methods: {
    add() {
      this.visible = true;
      this.detail = {};
    },
    edit(text) {
      const pidList = [];
      this.findFather(pidList, text.id);
      console.log("pidList", pidList);
      this.detail = {
        ...text,
        pidList
      };
      this.visible = true;
    },

    cancel() {
      this.detail = {};
      this.visible = false;
    },

    remove(text) {
      const that = this;
      this.$confirm({
        title: "确定要继续吗？",
        onOk() {
          const hide = that.$message.loading("操作中...");
          if (text.status === 1) {
            del({
              id: text.id
            })
              .then(() => {
                that.getDirectory();
              })
              .finally(() => {
                hide();
              });
          } else {
            update({
              ...text,
              status: 1
            })
              .then(() => {
                that.getDirectory();
              })
              .finally(() => {
                hide();
              });
          }
        }
      });
    }
  }
};
</script>